<template>
  <div class="czhWengao container" v-if="canShow">
    <Tanchuang :path="path" :id="id" />
    <Kcz :path="path" :id="id" />
    <!-- <Advertisement :needTitle="false" :title="adver.title" :src="adver.src" /> -->
    <Article :article="article" />
    <OpenApp :path="path" :id="id" />
    <!-- <Advertisement :title="adver2.title" :src="adver2.src" /> -->
    <Tuijian :title="tujianTitle" :list="tuijianList" :isCzh="true" @link="openlink" />
    <Comment :list="commentList" v-show="article.show_comment == 2 && commentList.length > 0" :path="path" :id="id" />

    <OpenApp2 :id="id" :path="path" />
  </div>
</template>

<script>
  import Tanchuang from "@/components/tanchuang";
  import Kcz from "@/components/kcz";
  import Advertisement from "@/components/advertisement";
  import Tuijian from "@/components/tuijian";
  import OpenApp2 from "@/components/openApp2";
  import OpenApp from "@/components/openApp";
  import linkApp from "@/utils/linkApp";
  import Article from "@/components/article";
  import Comment from "@/components/comment";
  import { getCzhArticle, getCzhTuijian, getCzhCommit, getCzhRecommend } from "@/utils/api";
  import { wxShare } from "@/utils/wxShare";
  export default {
    name: "czhWengao",
    components: {
      Tanchuang,
      Kcz,
      Advertisement,
      Tuijian,
      OpenApp2,
      OpenApp,
      Article,
      Comment,
    },
    data() {
      return {
        canShow: false,
        id: "",
        path: "czDetail",
        adver: {
          title: "",
          src: "",
        },
        adver2: {
          title: "",
          src: "",
        },
        article: {
          title: "",
          source: "",
          time: "",
          readNum: "",
          content: "",
        },
        tuijianList: [],
        tujianTitle: "推荐阅读",
        commentList: [],
        otherlist: [],
        share_config: {},
      };
    },
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      async getCzhArticle(id) {
        const res = await getCzhArticle(id);
        const data = res.data;
        this.article = {
          title: data.title,
          source: data.source || data.author || "",
          time: data.created_at,
          readNum: data.view_count,
          content: data.content,
          show_comment: data.show_comment,
        };
        this.canShow = true;
        document.title = data.title;
        this.share_config = data.share_config;
        wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
      },
      async getCzhTuijian(id) {
        const res = await getCzhTuijian(id);
        const data = res.data;
        this.tuijianList = data.slice(0, 3).map(val => {
          return {
            content: val.title,
            source: val.source,
            src: val.thumbnails[0].src,
            source_link: val.source_link,
          };
        });
      },
      async getCzhCommit(id) {
        const res = await getCzhCommit(id);
        const data = res.data;
        this.commentList = data.slice(0, 3).map(val => {
          return {
            avatar: val.avatar,
            name: val.user_display_name,
            time: val.created_at_human,
            like: val.like_count,
            content: val.content,
            show_comment: val.show_comment,
          };
        });
      },
      async getCzhRecommend() {
        const res = await getCzhRecommend();
        const data = res.data;
        this.otherlist = data.slice(0, 3).map(val => {
          return {
            src: val.avatar,
            name: val.title,
          };
        });
      },
      openlink(val) {
        window.location.href = val.source_link;
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getCzhArticle(this.id);
      this.getCzhTuijian(this.id);
      this.getCzhCommit(this.id);
      this.getCzhRecommend();
    },
  };
</script>

<style lang="scss">
  .czhWengao {
  }
</style>
