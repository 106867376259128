<template>
  <div class="advertisement" v-if="src.length > 0">
    <!-- <div @click="linkApp"> -->
    <div>
      <div class="ad-title" v-if="needTitle">{{ title }}</div>
      <img :src="src" />
      <div class="ad-font">广告</div>
    </div>
  </div>
</template>

<script>
  import linkApp from "@/utils/linkApp";
  import { getAdvert } from "@/utils/api";
  import { isWeixin } from "@/utils/common";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  export default {
    name: "advertisement",
    props: {
      needTitle: {
        type: Boolean,
        default: true,
      },
      pos: {
        type: String,
        default: "", //顶部top底部bottom
      },
      path: {
        type: String,
        default: "",
      },
      id: {
        type: [String, Number],
        default: "",
      },
    },
    data() {
      return {
        appId: "wx720bb4d01c924127",
        title: "",
        src: "",
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
      };
    },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          id: this.id,
        });
      },
    },
    created() {
      this.getAdvert();
    },
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      async getAdvert() {
        const res = await getAdvert(this.pos);
        const data = res.data.data[0];
        this.title = data.title;
        this.src = data.thumb_img.src;
      },
    },
  };
</script>

<style lang="scss" scoped>
  //广告
  .advertisement {
    width: 92%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    border-bottom: 1px solid #eee;
    .ad-title {
      font-size: 0.45rem;
      font-weight: 500;
      line-height: 0.75rem;
      color: #333333;
      padding: 0.2rem 0;
    }
    img {
      width: 100%;
      height: auto;
      border-radius: 0.2rem;
    }
    .ad-font {
      width: 9.2rem;
      margin: 0.3rem auto;
      font-size: 0.32rem;
      font-weight: 500;
      line-height: 0.59rem;
      color: #8d8d8d;
      opacity: 1;
    }
  }
</style>
