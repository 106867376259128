<template>
  <div class="comment" v-show="list.length > 0">
    <div class="comment-title">热门评论</div>
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <div class="comment-left">
          <img :src="item.avatar" class="avatar" />
          <div>
            <div class="comment-name">{{ item.name }}</div>
            <p class="comment-time">{{ item.time }}</p>
          </div>
        </div>
        <div class="comment-right"></div>
        <div class="comment-content">
          {{ item.content }}
        </div>
      </li>
    </ul>
    <div class="comment-btns">
      <div class="comment-btn linkApp">
        <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
          <script type="text/wxtag-template">
            <style>
              .btn{
                width: 100%;
                height: 40px;
              }
            </style>
              <div class="btn"></div>
          </script>
        </wx-open-launch-app>
        <div @click="linkApp">写评论</div>
      </div>
      <div class="comment-btn linkApp">
        <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
          <script type="text/wxtag-template">
            <style>
              .btn{
                width: 100%;
                height: 40px;
              }
            </style>
              <div class="btn"></div>
          </script>
        </wx-open-launch-app>
        <div @click="linkApp">看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
  import linkApp from "@/utils/linkApp";
  import { isWeixin } from "@/utils/common";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  export default {
    name: "comment",
    props: {
      list: {
        type: Array,
        default() {
          return [];
        },
      },
      id: {
        type: [String, Number],
        default: "",
      },
      path: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
      };
    },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          id: this.id,
        });
      },
    },
    methods: {
      openError() {
        this.$router.push({ name: "index" });
      },
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
    },
  };
</script>

<style lang="scss" scoped>
  //文章下的评论
  .comment {
    width: 92%;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 20px;
    .comment-title {
      font-size: 20px;
      font-weight: 800;
      color: #0b0b0b;
    }
    ul {
      li {
        padding: 20px 0;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        .comment-left {
          font-size: 18px;
          line-height: 1.5;
          color: #363636;
          display: flex;
          align-items: center;
          flex: none;
          width: 70%;
          .avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 20px;
          }
          p {
            font-size: 16px;
            line-height: 1.5;
            color: #9c9c9c;
          }
        }
        .comment-right {
          position: relative;
          width: 30%;
          text-align: right;
          flex: none;
          font-size: 16px;
          line-height: 1.5;
          color: #929292;
          img {
            width: 18px;
            height: 18px;
            margin-left: 5px;
          }
        }
        .comment-content {
          width: 100%;
          font-size: 16px;
          line-height: 1.5;
          color: #2f2f2f;
          padding-left: 80px;
        }
        &:nth-last-child(1) {
          border: none;
        }
      }
    }
    .comment-btns {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .comment-btn {
        width: 44%;
        position: relative;
        text-align: center;
        height: 40px;
        line-height: 40px;
        border: 1px solid #f04040;
        border-radius: 4px;
        color: #f04040;
        font-size: 18px;
      }
    }
    .likeImg {
      position: relative;
    }
  }
</style>
