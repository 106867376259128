<template>
  <div class="openApp">
    <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
      <script type="text/wxtag-template">
        <style>.btn{
                width: 100%;
                height: 40px;
              }</style>
          <div class="btn"></div>
      </script>
    </wx-open-launch-app>
    <div class="linkApp" @click="linkApp">打开APP，阅读体验更佳</div>
  </div>
</template>

<script>
  import linkApp from "@/utils/linkApp";
  import { isWeixin } from "@/utils/common";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  export default {
    name: "openApp",
    data() {
      return {
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
      };
    },
    props: {
      path: {
        type: String,
        default: "",
      },
      id: {
        type: [String, Number],
        default: "",
      },
    },
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      openError() {
        
        this.$router.push({ name: "index" });
      },
    },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: "/" + this.path,
          id: this.id,
        });
      },
    },
    created() {},
  };
</script>

<style lang="scss" scoped>
  //打开APP
  .openApp {
    margin: 0 auto;
    margin-bottom: 0.4rem;
    width: 92%;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border: 1px solid #f04040;
    border-radius: 4px;
    font-size: 18px;
    color: #f04040;
    position: relative;
  }
</style>
